/**
 * @file FeatureService.tsx
 * @description Contains functions to interact with the features API: fetching, updating, adding, and deleting features.
 * @author Sebastiaan Koedam
 */

import apiFetch from '../util/FetchApi';
import { Feature } from '../types/FeatureTypes';

/**
 * Fetches all features from the API.
 * @returns {Promise<Feature[]>} A promise resolving to an array of Feature objects.
 * @throws {Error} If the request fails.
 */
export async function fetchFeatures(): Promise<Feature[]> {
    try {
        return await apiFetch<Feature[]>('features/fetch');
    } catch {
        throw new Error('Failed to fetch features.');
    }
}

/**
 * Updates the status of a feature.
 * @param {number} FeatureID The unique identifier of the feature.
 * @param {string} Status The new status of the feature (enabled or disabled).
 * @returns {Promise<void>} A promise that resolves when the update is complete.
 * @throws {Error} If the request fails.
 */
export async function statusFeature(FeatureID: number, Status: string): Promise<void> {
    try {
        await apiFetch(`features/status/${FeatureID}`, {
            method: 'PUT',
            body: JSON.stringify({ Status }),
            headers: { 'Content-Type': 'application/json' },
        });
    } catch {
        throw new Error('Failed to update feature status.');
    }
}

/**
 * Edits an existing feature.
 * @param {number} FeatureID The unique identifier of the feature.
 * @param {string} Name The new name of the feature.
 * @param {string} Description The new description of the feature.
 * @returns {Promise<void>} A promise that resolves when the edit is complete.
 * @throws {Error} If the request fails.
 */
export async function editFeature(FeatureID: number, Name: string, Description: string): Promise<void> {
    try {
        await apiFetch(`features/edit/${FeatureID}`, {
            method: 'PATCH',
            body: JSON.stringify({ Name, Description }),
            headers: { 'Content-Type': 'application/json' },
        });
    } catch {
        throw new Error('Failed to edit feature.');
    }
}

/**
 * Deletes a feature from the API.
 * @param {number} FeatureID The unique identifier of the feature to delete.
 * @returns {Promise<void>} A promise that resolves when the deletion is complete.
 * @throws {Error} If the request fails or if FeatureID is invalid.
 */
export async function deleteFeature(FeatureID: number): Promise<void> {
    if (typeof FeatureID !== 'number' || FeatureID <= 0) {
        throw new Error('Invalid FeatureID.');
    }
    try {
        await apiFetch(`features/delete/${FeatureID}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        });
    } catch {
        throw new Error('Failed to delete feature.');
    }
}

/**
 * Adds a new feature to the API.
 * @param {string} Name The name of the new feature.
 * @param {string} Description The description of the new feature.
 * @returns {Promise<Feature>} A promise resolving to the added Feature object.
 * @throws {Error} If the request fails.
 */
export async function addFeature(Name: string, Description: string): Promise<Feature> {
    try {
        return await apiFetch<Feature>('features/add', {
            method: 'POST',
            body: JSON.stringify({ Name, Description }),
            headers: { 'Content-Type': 'application/json' },
        });
    } catch {
        throw new Error('Failed to add feature.');
    }
}
